import React from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import { debounce, loadScript } from '../../../helper/util.helper';

const GetLocation = ({ addressSelected, defaultValue, label, address }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const isDefaultSelected = React.useRef(false);
  const autocompleteService = React.useRef(false);

  const updateDefaultValue = () => {
    if (!autocompleteService.current && typeof window !== 'undefined' && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (defaultValue && !isDefaultSelected.current && autocompleteService.current) {
      fetch({ input: inputValue || defaultValue, types: ['(cities)'] }, (results) => {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }
        if (defaultValue && !isDefaultSelected.current) {
          setInputValue(defaultValue);
          setOptions(newOptions);
          addressSelected(results[0]);
          isDefaultSelected.current = true;
        }
      });
    }
  };
  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        // eslint-disable-next-line no-undef
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAP_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }
    document.querySelector('#google-maps').addEventListener('load', () => {
      updateDefaultValue();
    });
    loaded.current = true;
  }

  updateDefaultValue();

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue, types: ['(cities)'] }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  React.useEffect(() => {
    setValue(address);
  }, [address]);

  return (
    <Autocomplete
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      includeInputInList
      autoComplete
      noOptionsText={inputValue ? 'No locations' : 'Start typing to see options'}
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        addressSelected(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="City, State, Country"
          // InputProps={{
          //   ...params.InputProps,
          //   startAdornment: (
          //     <InputAdornment position="end">
          //       <LocationCityIcon color="primary" />
          //     </InputAdornment>
          //   )
          // }}
          inputProps={{
            ...params.inputProps
          }}
          fullWidth
          size="small"
        />
      )}
      renderOption={(props, option) => {
        return (
          <Grid {...props} container alignItems="center">
            <Grid item>
              <LocationCityIcon color="primary" />
            </Grid>
            <Grid item xs>
              {option.structured_formatting && option.structured_formatting.main_text}

              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting && option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default GetLocation;
