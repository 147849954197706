import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TaxRates from './tax-rates/TaxRates';
import PaymentMethod from './payment-method/PaymentMethod';
import Address from './address/Address';
import BankDetails from './bank-details/BankDetails';
import PanDetails from './pan-details/PanDetails';
import BusinessType from './business-type/BusinessType';
import toast from 'react-hot-toast';
import { useStoreState } from 'easy-peasy';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';
import { auth, database, storage } from '../../../../helper/firebase.helper';
import Loader from '../../../../shared/components/loader/Loader';
import { savePaymentSettings } from '../../../../helper/api.helper';
import { doc, getDoc } from 'firebase/firestore';

const PAN_REGEX = /^[a-zA-Z]{5}\d{4}[a-zA-Z]{1}$/;

const PaymentSettings = () => {
  const user = useStoreState((state) => state.user);
  const [detailsUpdated, setDetailsUpdated] = useState(false);
  const [fetchPaymentMethod, setFetchPaymentMethod] = useState(false);
  const [taxRates, setTaxRates] = useState({ cgst: 0, sgst: 0, igst: 0 });
  const [businessType, setBusinessType] = useState('individual');
  const [pan, setPan] = useState({
    name: '',
    panNumber: '',
    proofUrl: '',
    panFile: null,
    deletePanProof: false
  });

  const [accountDetails, setAccountDetails] = useState({
    ifscCode: '',
    name: '',
    accountNumber: ''
  });

  const [address, setAddress] = useState({
    street1: '',
    street2: '',
    city: '',
    state: '',
    country: '',
    postal_code: ''
  });

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchPaymentDetails = async () => {
      const paymentDetailsRef = doc(database, `practitioners/${user.uid}/settings/payment`);
      const paymentDetailsSnap = await getDoc(paymentDetailsRef);

      if (paymentDetailsSnap.exists()) {
        const storedBusinessType = paymentDetailsSnap.data().businessType || 'individual';
        const storedPanDetails = paymentDetailsSnap.data().panDetails || {
          name: '',
          panNumber: '',
          proofUrl: '',
          panFile: null,
          deletePanProof: false
        };
        const storedAccountDetails = paymentDetailsSnap.data().accountDetails || {
          ifscCode: '',
          name: '',
          accountNumber: ''
        };
        const storedAddressDetails = paymentDetailsSnap.data().address || {
          street1: '',
          street2: '',
          city: '',
          state: '',
          country: '',
          postal_code: ''
        };
        setAccountDetails(storedAccountDetails);
        setAddress(storedAddressDetails);
        setBusinessType(storedBusinessType);
        setPan({
          ...pan,
          name: storedPanDetails.name,
          panNumber: storedPanDetails.panNumber,
          proofUrl: storedPanDetails.proofUrl
        });
        setLoading(false);
      }
      setLoading(false);
    };

    fetchPaymentDetails();
  }, []);

  const savePaymentDetails = async (e) => {
    e.preventDefault();
    if (pan?.name?.length < 4 || pan?.name?.length > 200) {
      toast.error('Name should be between 4 and 200 characters');
      return;
    }
    if (!PAN_REGEX.test(pan?.panNumber)) {
      toast.error('Invalid PAN Number. Format: AAAPL1234C');
      return;
    }
    if (pan?.panNumber?.charAt(3) !== 'P') {
      toast.error(
        'Please enter your Individual PAN. Business PAN is not allowed for the selected business type.'
      );
      return;
    }
    if (address.postal_code?.length !== 6) {
      toast.error('Postal code should be of 6 numbers');
      return;
    }
    if (!address?.city) {
      toast.error('Please select city.');
      return;
    }
    if (!auth?.currentUser?._delegate?.phoneNumber) {
      toast.error('Add phone number to link bank account.');
      return;
    }
    setLoading(true);
    if (pan.panFile) {
      const user = auth.currentUser;
      const userId = user.uid;
      const panFolderRef = ref(storage, `users/${userId}/proofs/pan`);
      const deletePreviousExpFiles = listAll(panFolderRef)
        .then((res) => {
          const deletePromises = res.items.map((itemRef) => {
            return deleteObject(itemRef);
          });
          return Promise.all(deletePromises);
        })
        .catch((error) => {
          console.error('Error deleting previous files: ', error);
        });
      const timestamp = Date.now();
      const panProofRef = ref(
        storage,
        `users/${userId}/proofs/pan/${pan.panFile.name}.${timestamp}`
      );
      uploadBytes(panProofRef, pan.panFile).then(async () => {
        const panURL = await getDownloadURL(panProofRef);
        setPan({ ...pan, proofUrl: panURL, panFile: null });
        savePaymentSettings({
          businessType,
          panDetails: { name: pan.name, panNumber: pan.panNumber, proofUrl: panURL },
          address,
          accountDetails,
          phoneNumber: auth?.currentUser?._delegate?.phoneNumber
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === 'success') {
              setFetchPaymentMethod(!fetchPaymentMethod);
              toast.success('Bank account linked successfully!');
              setLoading(false);
            } else {
              setLoading(false);
              toast.error(res.message);
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error(err.message);
          });
      });
    } else if (pan.deletePanProof && pan.proofUrl) {
      const fileRef = ref(storage, pan.proofUrl);
      deleteObject(fileRef);
      setPan({ ...pan, proofUrl: '', deletePanProof: false });
      savePaymentSettings({
        businessType,
        panDetails: { name: pan.name, panNumber: pan.panNumber, proofUrl: '' },
        address,
        accountDetails,
        phoneNumber: auth?.currentUser?._delegate?.phoneNumber
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setFetchPaymentMethod(!fetchPaymentMethod);
            toast.success('Bank account linked successfully!');
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    } else {
      savePaymentSettings({
        businessType,
        panDetails: { name: pan.name, panNumber: pan.panNumber, proofUrl: '' },
        address,
        accountDetails,
        phoneNumber: auth?.currentUser?._delegate?.phoneNumber
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 'success') {
            setFetchPaymentMethod(!fetchPaymentMethod);
            toast.success('Bank account linked successfully!');
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    }
    setDetailsUpdated(false);
  };
  return (
    <div className="payment-settings-wrapper">
      {isLoading ? <Loader /> : null}

      <h4 className="heading margin-top--sm">Payment Methods for Clients </h4>
      <PaymentMethod setLoading={setLoading} fetchPaymentMethod={fetchPaymentMethod} />
      <form onSubmit={savePaymentDetails}>
        <BusinessType businessType={businessType} setBusinessType={setBusinessType} />
        <PanDetails pan={pan} setPan={setPan} setDetailsUpdated={setDetailsUpdated} />

        <Address address={address} setAddress={setAddress} setDetailsUpdated={setDetailsUpdated} />
        <BankDetails
          accountDetails={accountDetails}
          setAccountDetails={setAccountDetails}
          setDetailsUpdated={setDetailsUpdated}
        />
        {/* <TaxRates
          pan={pan}
          setPan={setPan}
          taxRates={taxRates}
          setTaxRates={setTaxRates}
          setDetailsUpdated={setDetailsUpdated}
        /> */}
        <Button
          type="submit"
          style={{ width: '120px', marginTop: '15px' }}
          variant="contained"
          color="primary"
          disabled={!detailsUpdated}>
          Save
        </Button>
      </form>

      <style jsx>{`
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
        .field-container {
          max-width: 500px;
        }
      `}</style>
    </div>
  );
};

export default PaymentSettings;
