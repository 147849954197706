import { Button } from '@mui/material';
import { useStoreState } from 'easy-peasy';
import React from 'react';
import { Helmet } from 'react-helmet';

const Plan = () => {
  const practitioner = useStoreState((state) => state.user);
  return (
    <div style={{ padding: 'var(--space-unit)' }} className="plan-wrapper margin-top--sm">
      <Helmet>
        <title>Plan - DeTalks</title>
      </Helmet>
      <div className="heading-container flex-container justify-between">
        <h3 className="heading">Plan</h3>
        <Button
          variant="contained"
          color="primary"
          href={`/register/confirm-plan?token=${practitioner.uid}`}>
          Upgrade Plan
        </Button>
      </div>{' '}
      <style jsx>
        {`
          .plan-wrapper {
            // margin: 0px 20px 100px 20px;
          }
          .heading-container {
            align-items: center;
            border-bottom: 1px solid var(--border-gray);
            padding-bottom: 10px;
          }
          .heading {
            margin: 0px;
            color: #1c1c1e;
            font-size: 1.3em;
            display: flex;
            align-items: center;
          }
        `}
      </style>
    </div>
  );
};

export default Plan;
