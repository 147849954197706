import React from 'react';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const AddOnPlans = ({ addOnData, handleAddOns, planName }) => {
  return (
    <div>
      <div className="flex-container align-center margin-top--xxs margin-bottom--xxs">
        <p className="label">Practitioner seats:</p>
        <TextField
          disabled={planName === 'advanced' || planName === 'professional'}
          size="small"
          variant="outlined"
          style={{
            maxWidth: `${115 + addOnData.seats.toString.length * 10}px`
          }}
          value={addOnData.seats}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={planName === 'advanced' || planName === 'professional'}
                  onClick={() =>
                    handleAddOns(planName, {
                      ...addOnData,
                      seats: addOnData.seats - 1 || 1
                    })
                  }
                  size="small">
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={planName === 'advanced' || planName === 'professional'}
                  onClick={() =>
                    handleAddOns(planName, {
                      ...addOnData,
                      seats: addOnData.seats + 1
                    })
                  }
                  size="small">
                  <AddIcon color="primary" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div>
      {/* <div className="flex-container align-center margin-top--xxs margin-bottom--xxs">
        <p className="label">Monthly assessments:</p>
        <TextField
          size="small"
          variant="outlined"
          style={{
            maxWidth: `${125 + addOnData.administrations.toString.length * 10}px`
          }}
          value={addOnData.administrations}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={() =>
                    handleAddOns(planName, {
                      ...addOnData,
                      administrations: addOnData.administrations - 50 || 50
                    })
                  }
                  size="small">
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    handleAddOns(planName, {
                      ...addOnData,
                      administrations: addOnData.administrations + 50
                    })
                  }
                  size="small">
                  <AddIcon color="primary" />
                </IconButton>
              </InputAdornment>
            )
          }}
        />
      </div> */}
      <style jsx>{`
        .label {
          margin-right: 10px;
        }
      `}</style>
    </div>
  );
};

export default AddOnPlans;
