import React, { useEffect, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import toast from 'react-hot-toast';
import { Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Proof = ({ path, onUpload, existingUrl, deleteProofFile }) => {
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (existingUrl) {
      const decodedUrl = decodeURIComponent(existingUrl);
      let extractedFileName = decodedUrl.split('/').pop().split('?')[0];
      const lastDotIndex = extractedFileName.lastIndexOf('.');
      if (lastDotIndex !== -1) {
        extractedFileName = extractedFileName.substring(0, lastDotIndex);
      }
      setFileName(extractedFileName);
    }
  }, [existingUrl]);

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.files[0]?.size > 5242880) {
        toast.error(`File size shouldn't exceed 5MB`);
        event.target.files = '';
        return;
      } else {
        await onUpload(file);
        setFileName(file.name);
      }
    }
  };

  const deleteProof = async () => {
    if (fileName || existingUrl) {
      setFileName('');
      deleteProofFile();
    }
    // const decodedPath = decodeURIComponent(
    //   existingUrl.split("/o/")[1].split("?")[0]
    // );
    // const fileRef = ref(storage, decodedPath);
    // await deleteObject(fileRef);
  };

  return (
    <div className="flex-container">
      {fileName && (
        <IconButton color="secondary" size="small" onClick={deleteProof}>
          <CloseIcon />
        </IconButton>
      )}
      <input
        type="file"
        accept="application/pdf/image"
        id={`upload-file-input-${path}`}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {!existingUrl && !fileName && (
        <label htmlFor={`upload-file-input-${path}`}>
          <Button
            variant="text"
            color="primary"
            startIcon={existingUrl ? null : <UploadIcon />}
            size="small"
            component="span">
            {path === 'gstin'
              ? 'Upload GSTIN Certificate'
              : path === 'pan'
                ? 'Upload PAN Card'
                : 'Upload proof'}
          </Button>
        </label>
      )}
      {fileName && (
        <small className="margin-top--xs">
          <a href={existingUrl} target="_blank" rel="noreferrer" style={{ color: '#0884ff' }}>
            {fileName.length > 40 ? fileName.slice(0, 40) + '...' : fileName}
          </a>
        </small>
      )}
    </div>
  );
};

export default Proof;
