import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Fab from '@mui/material/Fab';
import toast from 'react-hot-toast';
import { getPersonalData, storePersonalToDb } from './shared/profile.service';
import Modalities from './modalities/Modalities';
import Languages from './languages/Languages';
import WorksWith from './works-with/WorksWith';
import Specialities from './specialities/Specialities';
import Experience from './experience/Experience';
import License from './license/License';
import Education from './education/Education';
import AboutMe from './about-me/AboutMe';
import Name from './name/Name';
import Role from './role/Role';
import GovernmentId from './government-id/GovernmentId';
import Picture from './profile-picture/Picture';
import Loader from '../../../shared/components/loader/Loader';
import ProfileLink from './profile-link/ProfileLink';
import LoginDetails from './login-details/LoginDetails';

const verified = true;

const Personal = () => {
  const [aboutMeData, setAboutMeData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [profileChanged, setProfileChanged] = useState(false);
  const { handleSubmit, control, setValue, reset, getValues } = useForm();

  const setCroppedImage = (imageName) => (cropData) => {
    setValue(imageName, cropData);
    setProfileChanged(true);
  };

  const onSubmit = async (data) => {
    if (verified) {
      // if (!data.governmentIdProof && !data.governmentId.proofUrl) {
      //   toast.error('Please upload the proof for Government ID');
      //   return;
      // }
      // for (let i = 0; i < data.education?.length; i++) {
      //   if (!data?.education[i]?.proofUrl && !data?.educationProof?.[i]?.file) {
      //     toast.error(`Please upload the proof for Education ${i + 1}`);
      //     return;
      //   }
      // }
      // if (!data.experienceProof && !data.experience?.proofUrl) {
      //   toast.error('Please upload the proof for Experience');
      //   return;
      // }
    }

    try {
      await toast.promise(storePersonalToDb(data), {
        loading: 'Saving...',
        success: 'Saved!',
        error: `Couldn't save`
      });
      const updatedData = await getPersonalData();
      setAboutMeData(updatedData);
      setProfileChanged(false);
    } catch (error) {
      toast.error('Error saving data:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const personalData = await getPersonalData();
        setAboutMeData(personalData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error fetching data:', error);
      }
    };
    if (!aboutMeData) {
      fetchData();
    }
  }, [aboutMeData]);

  useEffect(() => {
    reset(aboutMeData);
  }, [aboutMeData, reset]);

  return (
    <div className="container">
      <Helmet>
        <title>Profile - DeTalks</title>
      </Helmet>
      {isLoading ? <Loader /> : null}
      <LoginDetails />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Picture control={control} setCroppedImage={setCroppedImage} />

        <Name control={control} setProfileChanged={setProfileChanged} />

        <ProfileLink control={control} verified={verified} setProfileChanged={setProfileChanged} />

        {/* <Role control={control} verified={verified} />           setProfileChanged={setProfileChanged}
         */}

        <GovernmentId
          control={control}
          setValue={setValue}
          getValues={getValues}
          verified={verified}
          setProfileChanged={setProfileChanged}
        />

        <AboutMe control={control} setProfileChanged={setProfileChanged} />

        <Education
          control={control}
          setValue={setValue}
          getValues={getValues}
          verified={verified}
          setProfileChanged={setProfileChanged}
        />

        <License
          control={control}
          setValue={setValue}
          getValues={getValues}
          setProfileChanged={setProfileChanged}
        />

        <Experience
          control={control}
          setValue={setValue}
          getValues={getValues}
          verified={verified}
          setProfileChanged={setProfileChanged}
        />

        <Specialities control={control} setProfileChanged={setProfileChanged} />

        <WorksWith control={control} setProfileChanged={setProfileChanged} />

        <Languages control={control} setProfileChanged={setProfileChanged} />

        <Modalities control={control} setProfileChanged={setProfileChanged} />

        <div className="floating-button">
          <Fab
            style={{ width: '120px' }}
            variant="extended"
            color="primary"
            type="submit"
            disabled={!profileChanged}>
            Save
          </Fab>
        </div>
      </form>
      <style jsx>{`
        .floating-button {
          position: fixed;
          bottom: 60px;
          right: 10%;
        }
      `}</style>
    </div>
  );
};

export default Personal;
