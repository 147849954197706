import React, { useEffect, useState } from 'react';
import Proof from '../../../../profile/personal/shared/Proof';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

const PanDetails = ({ pan, setPan, setDetailsUpdated }) => {
  const [panProofUrl, setPanProofUrl] = useState('');

  useEffect(() => {
    setPanProofUrl(pan.proofUrl);
  }, []);

  const handlePANChange = (field, value) => {
    setPan({ ...pan, [field]: value });
    setDetailsUpdated(true);
  };

  const handlePANProofUpload = (file) => {
    setPan({ ...pan, panFile: file });
    setDetailsUpdated(true);
  };

  const deleteProofFile = async () => {
    setPanProofUrl('');
    setPan({ ...pan, deletePanProof: true, panFile: null });
    setDetailsUpdated(true);
  };
  return (
    <div>
      <h4 className="heading">
        <BadgeOutlinedIcon color="primary" style={{ marginBottom: '-3px' }} /> PAN Card
      </h4>
      <div className="field-container flex-container direction-column">
        <TextField
          required
          variant="outlined"
          name="name"
          label="Name as per PAN Card"
          placeholder="Name"
          size="small"
          value={pan.name}
          onChange={(e) => handlePANChange('name', e.target.value)}
          style={{ marginTop: '15px' }}
        />
        <p className="field text--xs helper-text gray-text" style={{ marginTop: '5px' }}>
          The name you provide must exactly match with the name associated with your PAN card.
        </p>
        <TextField
          required
          variant="outlined"
          name="panNumber"
          label="PAN Number"
          placeholder="PAN Number"
          size="small"
          value={pan.panNumber}
          onChange={(e) => handlePANChange('panNumber', e.target.value)}
          style={{ marginTop: '15px' }}
        />
        <Proof
          existingUrl={panProofUrl}
          path="pan"
          onUpload={handlePANProofUpload}
          deleteProofFile={deleteProofFile}
        />
      </div>
      <style jsx>{`
        .field-container {
          max-width: 500px;
        }
        .heading {
          font-size: 18px;
        }
      `}</style>
    </div>
  );
};

export default PanDetails;
