import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';

import Personal from './steps/Personal';
import Education from './steps/Education';
import Experience from './steps/Experience';
import toast from 'react-hot-toast';
import TopNav from '../../shared/components/TopNav/TopNav';
import Loader from '../../shared/components/loader/Loader';
import Service from './steps/Service';
import Availability from './steps/Availability';
import Domain from './steps/Domain';
import { Helmet } from 'react-helmet';
import { useStoreState } from 'easy-peasy';

const GetInfo = () => {
  const practitioner = useStoreState((state) => state.user);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const goNext = () => {
    if (activeTab === 5) {
      return;
    }
    setActiveTab((currTab) => currTab + 1);
  };

  const getItemBeforeCollapse = () => {
    if (activeTab === 0) return 2;
    if (activeTab === 1) return 2;
    if (activeTab === 2) return 3;
    if (activeTab === 3) return 0;
    if (activeTab === 4) return 0;
    if (activeTab === 5) return 0;
    if (activeTab === 6) return 0;
    return 2;
  };

  const getItemAfterCollapse = () => {
    if (activeTab === 0) return 0;
    if (activeTab === 1) return 0;
    if (activeTab === 2) return 0;
    if (activeTab === 3) return 2;
    if (activeTab === 4) return 2;
    if (activeTab === 5) return 2;
    if (activeTab === 6) return 3;
    return 1;
  };

  useEffect(() => {
    if (activeTab === 5) {
      navigate(`/register/confirm-plan?token=${practitioner.uid}`);
    }
  }, [activeTab]);

  return (
    <div>
      {loading ? <Loader /> : null}
      {/* <TopNav /> */}
      <Helmet>
        <title> About me - DeTalks </title>
      </Helmet>
      <div className="about-wrapper">
        <Breadcrumbs
          maxItems={window.innerWidth < 500 ? 2 : 6}
          itemsBeforeCollapse={window.innerWidth < 500 ? getItemBeforeCollapse() : 1}
          itemsAfterCollapse={window.innerWidth < 500 ? getItemAfterCollapse() : 1}
          separator={<hr style={{ width: '1vw' }} />}
          aria-label="steps">
          {/* <div className="flex-container align-center">
            {activeTab > 0 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Personal
          </div> */}
          <div className="flex-container align-center">
            {activeTab > 0 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Education
          </div>
          <div className="flex-container align-center">
            {activeTab > 1 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Experience
          </div>
          <div className="flex-container align-center">
            {activeTab > 2 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Availability
          </div>
          <div className="flex-container align-center">
            {activeTab > 3 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Services
          </div>
          <div className="flex-container align-center">
            {activeTab > 4 ? (
              <CheckCircleIcon fontSize="small" style={{ marginRight: '7px' }} color="success" />
            ) : (
              <PendingIcon fontSize="small" style={{ marginRight: '7px' }} />
            )}
            Domain
          </div>
        </Breadcrumbs>
        <div className="container margin-top--lg">
          {
            // activeTab === 0 ? (
            //   <Personal goNext={goNext} />
            // ) :
            activeTab === 0 ? (
              <Education
                activeTab={activeTab}
                loading={loading}
                setLoading={setLoading}
                goNext={goNext}
              />
            ) : activeTab === 1 ? (
              <Experience
                activeTab={activeTab}
                loading={loading}
                setLoading={setLoading}
                goNext={goNext}
              />
            ) : activeTab === 2 ? (
              <Availability
                activeTab={activeTab}
                loading={loading}
                setLoading={setLoading}
                goNext={goNext}
              />
            ) : activeTab === 3 ? (
              <Service
                activeTab={activeTab}
                loading={loading}
                setLoading={setLoading}
                goNext={goNext}
              />
            ) : (
              <Domain
                activeTab={activeTab}
                loading={loading}
                setLoading={setLoading}
                goNext={goNext}
              />
            )
          }
        </div>
      </div>
      <style jsx>{`
        .about-wrapper {
          min-height: 90vh;
          max-width: 800px;
          margin: auto;
          margin-top: 5vh;
          // text-align: center;
          flex-direction: column;
          padding: 10px;
        }
        .container {
          box-shadow: 0px 0 6px #e5e4e6;
          padding: 5%;
          border-radius: 10px;
        }
      `}</style>
      <style>{`
        .about-wrapper .MuiBreadcrumbs-ol {
          justify-content: center !important;
        }`}</style>
    </div>
  );
};

export default GetInfo;
