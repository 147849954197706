import React, { useState, useEffect, memo } from 'react';
// import {useSelector, useDispatch} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import style from './ConfirmPlan.module.scss';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Plans from './plans/Plans';

const ConfirmPlan = () => {
  const location = useLocation();
  const token = decodeURI(location.search.split('?token=')[1]);
  const [value, setValue] = useState(1);

  const selectDuration = (event) => {
    setValue(Number(event.target.value));
  };

  return (
    <div className={style.plan_wrapper}>
      <Helmet>
        <title>Confirm plan - DeTalks</title>
      </Helmet>
      {/* <div className={style.step}>
        <p>Confirm plan</p>
        <p>Step 2 of 4</p>
      </div> */}
      <div className={style.plan_header}>
        <p className={style.header_text}>Confirm plan</p>
        {/* <RadioGroup
          aria-label="Plan"
          name="Plan"
          value={value}
          onChange={selectDuration}
          style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            checked={value === 0}
            value={0}
            control={<Radio />}
            label="Monthly plans"
          />
          <FormControlLabel
            checked={value === 1}
            value={1}
            control={<Radio />}
            label={
              <p>
                Annual Plans
                <span className={style.offer}>Save upto 30%</span>
              </p>
            }
          />
        </RadioGroup> */}
      </div>
      <div className={style.plan_body}>
        <div className={style.plan_container}>
          <Plans token={token} billingCycle={value === 0 ? 'monthly' : 'annual'} />
        </div>
      </div>
    </div>
  );
};

export default ConfirmPlan;
