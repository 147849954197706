import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Route, Routes } from 'react-router-dom';
import RegisterNumber from './register-number/RegisterNumber';
import RegisterEmail from './register-email/RegisterEmail';
import VerifyEmail from './verify-email/VerifyEmail';
import Footer from '../shared/components/Footer';
import TopNav from '../shared/components/TopNav/TopNav';
import GetInfo from './get-info/GetInfo';
import ConfirmPlan from './confirm-plan/ConfirmPlan';

const Register = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#0884ff'
      },
      secondary: {
        main: '#73768c'
      }
    },
    typography: {
      button: {
        textTransform: 'capitalize'
      }
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <TopNav />
      <Routes>
        <Route path="/" element={<RegisterEmail />} />
        <Route path="/register-user" element={<RegisterEmail />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/register-number" element={<RegisterNumber />} />
        <Route path="/about-me" element={<GetInfo />} />
        <Route path="/confirm-plan" element={<ConfirmPlan />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default Register;
