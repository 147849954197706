import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Tooltip, Button } from '@mui/material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InfoIcon from '@mui/icons-material/Info';
import { useStoreState } from 'easy-peasy';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { database } from '../../../../../helper/firebase.helper';
import toast from 'react-hot-toast';

const PaymentMethod = ({ setLoading, fetchPaymentMethod }) => {
  const user = useStoreState((state) => state.user);
  const [paymentMethod, setPaymentMethod] = useState({
    cash: true,
    online: false
  });
  const [productId, setProductId] = useState('');
  const [detailsUpdated, setDetailsUpdated] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchPaymentMethod = async () => {
      const paymentRef = doc(database, `practitioners/${user.uid}/settings/payment`);
      const paymentSnap = await getDoc(paymentRef);

      if (paymentSnap.exists()) {
        const storedMethods = paymentSnap.data().paymentMethod || [];
        const storedProductID = paymentSnap.data().productId || '';
        setProductId(storedProductID);
        if (storedMethods?.length > 0) {
          setPaymentMethod({
            cash: storedMethods.includes(0),
            online: storedMethods.includes(1)
          });
        } else {
          setPaymentMethod({
            cash: true,
            online: false
          });
        }
        setLoading(false);
      }
      setLoading(false);
    };

    fetchPaymentMethod();
  }, [fetchPaymentMethod]);

  const handleChange = (field, value) => {
    setPaymentMethod((prev) => ({ ...prev, [field]: value }));
    setDetailsUpdated(true);
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (name === 'cash' && !checked && !paymentMethod.online) {
      toast.error('You must enable Online Payment before disabling Cash.');
      return;
    }
    if (name === 'online' && !checked && !paymentMethod.cash) {
      toast.error('You must enable Cash Payment before disabling Online Payment.');
      return;
    }
    handleChange(name, checked);
  };

  const savePaymentMethod = async (e) => {
    setLoading(true);
    e.preventDefault();
    const methods = [];
    if (paymentMethod.cash) methods.push(0);
    if (paymentMethod.online) methods.push(1);
    const paymentRef = doc(database, `practitioners/${user.uid}/settings/payment`);
    await setDoc(paymentRef, { paymentMethod: methods }, { merge: true });
    setDetailsUpdated(false);
    setLoading(false);
  };
  return (
    <div>
      <p className="margin-top--md" style={{ fontWeight: 'bold' }}>
        Payment Method
      </p>
      <div className="field-container">
        <FormControlLabel
          // disabled={!paymentMethod.online}
          sx={{ width: '100%' }}
          control={
            <Checkbox checked={paymentMethod.cash} onChange={handleCheckboxChange} name="cash" />
          }
          label="Cash"
        />
        <FormControlLabel
          sx={{ marginTop: '-10px' }}
          disabled={!productId}
          control={
            <Checkbox
              checked={paymentMethod.online}
              onChange={handleCheckboxChange}
              name="online"
            />
          }
          label={
            <span>
              Online Payment Gateway{' '}
              <span style={{ fontSize: '0.8rem' }}>
                (To enable, please complete your Business Details, PAN Card, Address, and Bank
                Details.)
              </span>
              <Tooltip title="Charges apply as per plan." aria-label="edit" arrow>
                <InfoIcon
                  color="secondary"
                  fontSize="small"
                  style={{ marginBottom: '-5px', marginLeft: '10px' }}
                />
              </Tooltip>
            </span>
          }
        />
      </div>
      <Button
        onClick={savePaymentMethod}
        style={{ width: '120px', marginTop: '15px' }}
        variant="contained"
        color="primary"
        disabled={!detailsUpdated}>
        Save
      </Button>
      <style jsx>{`
        .field-container {
          // max-width: 500px;
        }
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
      `}</style>
    </div>
  );
};

export default PaymentMethod;
