import { TextField } from '@mui/material';
import React from 'react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const BankDetails = ({ setDetailsUpdated, setAccountDetails, accountDetails }) => {
  const handleChange = (field, value) => {
    setAccountDetails((prev) => ({ ...prev, [field]: value }));
    setDetailsUpdated(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    handleChange(name, value);
  };
  return (
    <div>
      <h4 className="heading">
        <AccountBalanceIcon color="primary" style={{ marginBottom: '-3px' }} /> Bank Details
      </h4>
      <div className="field-container">
        <TextField
          required
          label="Account Holder Name"
          variant="outlined"
          placeholder="Account Holder Name"
          fullWidth
          style={{ marginTop: '10px', marginBottom: '15px' }}
          name="name"
          value={accountDetails.name}
          onChange={handleInputChange}
          size="small"
        />

        <TextField
          type="number"
          required
          label="Account Number"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '15px' }}
          name="accountNumber"
          placeholder="Account Number"
          value={accountDetails.accountNumber}
          onChange={handleInputChange}
          size="small"
          // InputLabelProps={{ shrink: true }}
        />

        <TextField
          required
          label="IFSC Code"
          placeholder="IFSC Code"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '15px' }}
          name="ifscCode"
          value={accountDetails.ifscCode}
          onChange={handleInputChange}
          size="small"
        />
      </div>
      <style jsx>{`
        .field-container {
          max-width: 500px;
        }
        .heading {
          border-bottom: 1px solid var(--border-gray);
          padding-bottom: 10px;
        }
      `}</style>
    </div>
  );
};

export default BankDetails;
