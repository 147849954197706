import { getIdToken } from 'firebase/auth';
import { auth } from './firebase.helper';

// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL;
// const BASE_URL = 'http://127.0.0.1:5001/detalks-test-23af7/asia-south1/api/';

export const makePost = async (url, data, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const makeGet = async (url, headers = {}) => {
  const token = auth.currentUser ? await getIdToken(auth.currentUser) : '';
  return fetch(`${BASE_URL}${url}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`,
      ...headers
    }
  });
};

export const registerPractitioner = (data) => {
  const url = `register/practitioner`;
  return makePost(url, data);
};

export const invitePractitioner = (data) => {
  const url = `practitioner/practice/invite`;
  return makePost(url, data);
};

export const acceptInvite = (data) => {
  const url = `practitioner/practice/accept-invite`;
  return makePost(url, data);
};

export const updateInvite = (data) => {
  const url = `practitioner/practice/update-invite`;
  return makePost(url, data);
};

export const getAllServices = () => {
  const url = `practitioner/services`;
  return makeGet(url);
};

export const getBookings = (data) => {
  const url = `practitioner/bookings`;
  return makePost(url, data);
};

export const cancelMeeting = (data) => {
  const url = `practitioner/cancel-meeting`;
  return makePost(url, data);
};

export const shareReportToClient = (data) => {
  const url = `practitioner/share-report`;
  return makePost(url, data);
};

export const storeSiteDomain = (data) => {
  const url = `practitioner/siteDomain`;
  return makePost(url, data);
};

export const savePaymentSettings = (data) => {
  const url = `practitioner/payment-details`;
  return makePost(url, data);
};
export const verifyPsypackIntegration = (data) => {
  const url = `practitioner/integrate/psypack`;
  return makePost(url, data);
};

export const removePsypackIntegration = () => {
  const url = `practitioner/integrate/remove-psypack`;
  return makePost(url);
};
