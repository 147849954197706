export const plans = [
  {
    heading: 'Beginner',
    tagLine: 'Ideal for therapists who are early in their career.',
    price: 'FREE',
    planName: 'free',
    listItems: [
      {
        text: 'Practice Management Tools',
        innerListItems: [
          'Booking/scheduling with reminders',
          'Intake forms, clinical notes',
          'Client portal',
          'Invoicing',
          'Basic Analytics and Reports',
          'Data Security Compliance',
          'Coming Soon: Secure messaging, blogging tools'
        ]
      },
      {
        text: 'Payment Gateway - 6.5% transaction charge'
      },
      {
        text: 'Custom Branded Practice webpage on DeTalks subdomain e.g., mypractice.detalks.com.'
      }
    ]
  },
  {
    heading: 'Professional',
    tagLine: 'Ideal for independent private practitioners.',
    price: 5999,
    planName: 'professional',
    listItems: [
      {
        text: 'Practice Management Tools',
        innerListItems: [
          'Booking/scheduling with reminders',
          'Intake forms, clinical notes',
          'Client portal',
          'Invoicing',
          'Basic Analytics and Reports',
          'Data Security Compliance',
          'Coming Soon: Secure messaging, blogging tools'
        ]
      },
      {
        text: 'Payment Gateway - 6.5% transaction charge'
      },
      {
        text: 'Custom Branded Practice webpage on DeTalks subdomain e.g., mypractice.detalks.com with option to link to your own domain like mypractice.com.'
      },
      {
        text: 'Add multiple seats'
      }
    ]
  },
  {
    heading: 'Advanced',
    tagLine: 'Ideal for counselling centres, EAPs and other facilities.',
    price: 11999,
    planName: 'advanced',
    listItems: [
      {
        text: 'Practice Management Tools',
        innerListItems: [
          'Booking/scheduling with reminders',
          'Intake forms, clinical notes',
          'Client portal',
          'Invoicing',
          'Basic Analytics and Reports',
          'Data Security Compliance',
          'Coming Soon: Secure messaging, blogging tools'
        ]
      },
      {
        text: 'Payment Gateway - 6.5% transaction charge'
      },
      {
        text: 'Custom Branded Practice webpage on DeTalks subdomain e.g., mypractice.detalks.com with option to link to your own domain like mypractice.com.'
      },
      {
        text: 'Add multiple seats'
      },
      {
        text: 'Advanced AI Analytics and Reports'
      }
    ]
  }
];
