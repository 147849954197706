import React from 'react';
import { Helmet } from 'react-helmet';
import EmailDomains from './email-domains/EmailDomains';
import PaymentSettings from './payment-settings/PaymentSettings';

const Settings = () => {
  return (
    <div className="settings-container">
      <Helmet>
        <title>Settings - DeTalks</title>
      </Helmet>
      <PaymentSettings />
      <EmailDomains />

      <style jsx>{`
        .settings-container {
          padding-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default Settings;
