import React, { useState } from 'react';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Button, TextField } from '@mui/material';
import GetLocation from '../../../../shared/components/GetLocation';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useStoreState } from 'easy-peasy';
import { auth } from '../../../../../helper/firebase.helper';

const Address = ({ address, setAddress, setDetailsUpdated }) => {
  const navigate = useNavigate();
  const user = useStoreState((state) => state.user);
  const handleChange = (field, value) => {
    setAddress((prev) => ({ ...prev, [field]: value }));
    setDetailsUpdated(true);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'postal_code' && value.length > 6) {
      toast.error('Postal code should be of 6 numbers');
      return;
    }
    handleChange(name, value);
  };

  const selectCity = (e) => {
    if (e?.terms[2]) {
      setAddress((prev) => ({
        ...prev,
        city: e?.terms[0]?.value,
        state: e?.terms[1]?.value,
        country: e?.terms[2]?.value
      }));
    } else {
      setAddress((prev) => ({
        ...prev,
        city: e?.terms[0]?.value,
        state: e?.terms[0]?.value,
        country: e?.terms[1]?.value
      }));
    }

    setDetailsUpdated(true);
  };

  return (
    <div>
      <h4 className="heading">
        <LocationOnOutlinedIcon color="primary" style={{ marginBottom: '-3px' }} /> Address
      </h4>
      <div className="field-container">
        <TextField
          required
          label="Street 1"
          variant="outlined"
          fullWidth
          style={{ marginTop: '10px', marginBottom: '15px' }}
          name="street1"
          placeholder="Street 1"
          value={address.street1}
          onChange={handleInputChange}
          size="small"
          inputProps={{ maxLength: 100 }}
        />

        <TextField
          required
          label="Street 2"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '15px' }}
          name="street2"
          placeholder="Street 2"
          value={address.street2}
          onChange={handleInputChange}
          size="small"
          inputProps={{ maxLength: 100 }}
        />

        <GetLocation
          label="City"
          defaultValue=""
          address={address.city}
          addressSelected={(e) => selectCity(e)}
        />

        <TextField
          type="number"
          required
          label="Postal Code"
          variant="outlined"
          fullWidth
          style={{ marginBottom: '5px', marginTop: '15px' }}
          name="postal_code"
          placeholder="Postal Code"
          value={address.postal_code}
          onChange={handleInputChange}
          size="small"
          inputProps={{ maxLength: 6 }}
          // InputLabelProps={{ shrink: true }}
        />

        <p className="margin-top--sm">
          {auth?.currentUser?._delegate?.phoneNumber ? (
            `Phone No. - ${auth?.currentUser?._delegate?.phoneNumber}`
          ) : (
            <Button
              size="small"
              variant="outlined"
              // onClick={() => navigate('/register/register-number')}>
              onClick={() =>
                navigate('/register/register-number', { state: { fromProfile: true } })
              }>
              Add Phone Number
            </Button>
          )}
        </p>
      </div>
      <style jsx>{`
        .field-container {
          max-width: 500px;
        }
        .heading {
          font-size: 18px;
        }
      `}</style>
    </div>
  );
};

export default Address;
