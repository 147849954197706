import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { selectFreePlan } from '../../../../shared/register.action';
// import { planIdEnum } from '../../../../../Shared/Constants/appConstants';
import toast from 'react-hot-toast';
import Plan from './plan/Plan';
import { plans } from '../planData';

const Plans = ({ billingCycle, token }) => {
  const navigate = useNavigate();
  const basePlan = {
    seats: 1
    // ,
    // administrations: 50
  };
  const [addOns, setAddOns] = useState({
    professional: basePlan,
    advanced: basePlan
  });
  const priceEnum = {
    // monthly: {
    //   free: 0,
    //   professional: 27,
    //   administrations: 15,
    //   seats: 15
    // },
    annual: {
      free: 0,
      professional: 5999,
      advanced: 11999,
      // administrations: 10,
      seats: 10
    }
  };

  const getPrice = (planName) => {
    return priceEnum[billingCycle][planName] * addOns[planName].seats;
  };

  const handleAddOns = (planName, addOnData) => {
    setAddOns({
      ...addOns,
      [planName]: addOnData
    });
  };

  const choosePlan = (planName) => {
    // console.log(addOns);
    if (planName === 'free') {
      toast.success('Beginner plan selected');
      navigate('/dashboard');
    } else if (planName === 'professional') {
      toast.success('Professional plan selected');
      // navigate('/dashboard');
    } else {
      toast.success('Advanced plan selected');
      // navigate('/dashboard');
    }
  };

  return (
    <section className="wrapper">
      <div className="plan-container flex-container">
        {plans?.map((plan) => (
          <Plan
            key={plan.planName}
            plan={plan}
            choosePlan={choosePlan}
            addOns={addOns}
            handleAddOns={handleAddOns}
            getPrice={getPrice}
          />
        ))}
      </div>
      <style jsx>{`
        .wrapper {
          padding: 2%;
        }
        .plan-container {
          justify-content: space-evenly;
          margin: auto;
          min-width: 340px;
          flex-wrap: wrap;
        }
        @media only screen and (max-width: 500px) {
          margin: 0;
        }
        @media only screen and (max-width: 380px) {
          .wrapper {
            padding: 0.5%;
          }
        }
      `}</style>
    </section>
  );
};

export default Plans;
