import React from 'react';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const BusinessType = ({ businessType }) => {
  return (
    <div>
      <h4
        className="heading"
        style={{ paddingBottom: '10px', borderBottom: '1px solid var(--border-gray)' }}>
        <StorefrontIcon color="primary" style={{ marginBottom: '-3px' }} /> Business Details
      </h4>
      <FormControl
        variant="outlined"
        size="small"
        fullWidth
        style={{ marginTop: '15px', maxWidth: '500px' }}>
        <InputLabel id="type">Type</InputLabel>
        <Select
          disabled
          labelId="type"
          label="Type"
          required
          size="small"
          value={businessType}
          // onChange={(event) => handleBusinessTypeChange(index, event)}
          fullWidth>
          <MenuItem value="individual" disabled>
            Individual
          </MenuItem>
        </Select>
      </FormControl>
      <style jsx>{`
        .field-container {
          max-width: 500px;
        }
        .heading {
          font-size: 18px;
        }
      `}</style>
    </div>
  );
};

export default BusinessType;
