import { Button } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import AddOnPlans from './AddOnPlans';

const Plan = ({ plan, choosePlan, addOns, handleAddOns, getPrice }) => {
  return (
    <div className="plan">
      <div className="heading">
        <h2 className="margin-top--xxxs">{plan.heading}</h2>
        <h3 className="margin-top--md margin-bottom--xs">{plan.tagLine} </h3>
        {plan.planName !== 'free' ? (
          <>
            <AddOnPlans
              addOnData={addOns[plan.planName]}
              handleAddOns={handleAddOns}
              planName={plan.planName}
            />
            <div className="price">
              ₹{getPrice(plan.planName)} per year
              {/* <p className="billing">
                when billed {billingCycle === 'annual' ? 'annually' : 'monthly'}
              </p> */}
            </div>
          </>
        ) : (
          <div className="price">{plan.price}</div>
        )}

        {plan.planName === 'advanced' || plan.planName === 'professional' ? (
          <p
            style={{
              color: '#0884ff',
              position: 'absolute',
              bottom: '35px'
            }}>
            Coming Soon
          </p>
        ) : (
          <Button
            variant="contained"
            onClick={() => choosePlan(plan.planName)}
            color="primary"
            style={{
              width: '150px',
              margin: '0px auto',
              position: 'absolute',
              bottom: '30px'
            }}>
            Select
          </Button>
        )}
      </div>
      <div className="line" />
      <div className="plan-body">
        <ul>
          {plan.listItems.map((item, index) => (
            <li key={item.text}>
              <p className="flex-container">
                <CheckIcon color="primary" style={{ width: '20px', marginRight: '7px' }} />
                {item.text}
              </p>
              {index === 0 && (
                <ul style={{ listStyleType: 'disc', marginLeft: '26px' }}>
                  {item.innerListItems.map((innerListItem) => (
                    <li key={innerListItem}>
                      <p>{innerListItem}</p>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .plan {
          flex-grow: 1;
          background-color: #fff;
          max-width: 350px;
          width: 45%;
          min-width: 350px;
          border-radius: 20px;
          margin: 1em 0em 1em 0em;
          box-shadow:
            7px 7px 21px #e3e3e3,
            -7px -7px 21px #ffffff;
        }
        @media only screen and (max-width: 500px) {
          margin: 0;
        }

        .heading {
          padding: 2em 1.5em 0em 1.5em;
          min-height: 300px;
          position: relative;
        }
        .billing {
          font-size: 0.5em;
          font-weight: normal;
        }
        h2 {
          font-size: 2em;
        }
        h3 {
          font-size: 1em;
        }
        .price {
          font-weight: 600;
          font-size: 1.7em;
          min-height: 70px;
        }
        .coming-soon {
          color: var(--brand-color);
          background-color: white;
          box-shadow: none;
          text-align: left;
          font-weight: bold;
        }
        .line {
          background-color: #f0eeef;
          height: 2px;
        }
        .plan-body {
          padding: 0.6em;
        }
        ul {
          padding: 1.1em;
          list-style-type: none;
        }
        li {
          margin-bottom: var(--space-md);
          color: #000000;
          font-weight: 500;
        }
        .note {
          font-size: 0.75em;
          font-weight: normal;
        }
        .link {
          color: var(--brand-color);
          text-decoration: underline;
          cursor: pointer;
        }
        .get-started {
          font-size: 1rem;
          margin: 5% 0%;
          display: block;
          width: 150px;
          text-align: center;
        }
        @media only screen and (max-width: 1220px) {
          .plan {
            max-width: 450px;
          }
        }
        @media only screen and (max-width: 380px) {
          .plan {
            min-width: 300px;
            max-width: 330px;
            width: 90%;
          }
        }
        @media only screen and (min-width: 700px) {
          .plan {
            margin: 1em;
          }
        }

        @media only screen and (min-width: 1300px) {
          .plan {
            max-width: 380px;
          }
        }
        @media only screen and (min-width: 1450px) {
          .plan {
            max-width: 430px;
          }
        }
        @media only screen and (min-width: 1600px) {
          .plan {
            max-width: 480px;
          }
        }
      `}</style>
    </div>
  );
};

export default Plan;
