import { Button } from '@mui/material';
import { useStoreState, useStoreActions } from 'easy-peasy';
import React from 'react';
import { useNavigate } from 'react-router';
import { PhoneAuthProvider, unlink } from 'firebase/auth';

import { auth } from '../../../../helper/firebase.helper';
import toast from 'react-hot-toast';

const LoginDetails = () => {
  const navigate = useNavigate();
  const user = useStoreState((state) => state.user);
  const setUser = useStoreActions((actions) => actions.setUser);

  const unlinkPhoneNumber = async () => {
    await toast.promise(unlink(auth.currentUser, PhoneAuthProvider.PROVIDER_ID), {
      loading: 'Unlinking phone number',
      success: 'Successfully unlinked phone number',
      error: (err) => `Failed to unlink phone number ${err.message}`
    });
    auth.currentUser.reload();
    setUser({ ...user, ...auth.currentUser });
  };

  return (
    <>
      <div className="heading-div ">
        <b>Login Details</b>
      </div>
      <div className="details-div margin-top--sm">
        <p>{user.displayName ? `Name - ${user.displayName}` : null}</p>
        <p className="margin-top--sm">{user.email ? `Email - ${user.email}` : null}</p>
        <p className="margin-top--sm">
          {auth?.currentUser?.phoneNumber ? (
            <div className="flex-container align-center">
              <div style={{ marginRight: '10px' }}>
                Phone No. - {auth?.currentUser?.phoneNumber}
              </div>
              <Button size="small" variant="outlined" color="error" onClick={unlinkPhoneNumber}>
                Unlink
              </Button>
            </div>
          ) : (
            <Button
              size="small"
              variant="outlined"
              onClick={() =>
                navigate('/register/register-number', { state: { fromProfile: true } })
              }>
              Add Phone Number
            </Button>
          )}
        </p>
      </div>
      <style jsx>{`

        .heading-div {
          border-bottom: 1px solid var(--border-gray);
          padding: 5px 5px 10px 0px;
          margin-top: 10px;
          font-size: 18px;
          color:black;
        }

        }
      `}</style>
    </>
  );
};

export default LoginDetails;
